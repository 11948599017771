/* eslint-disable import/no-cycle */
// Disabled to make this file readable

import dynamic from 'next/dynamic';
import { memo } from 'react';
import isEqual from 'react-fast-compare';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import { CONTENTFUL_TYPES } from '../ContentfulFields/types';
import ContentfulTemplate from '../ContentfulTemplate/ContentfulTemplate';

const ContentfulAdventCalendar = dynamic(
  () => import('../ContentfulFields/ContentfulAdventCalendar/ContentfulAdventCalendar'),
);
const ContentfulContainer = dynamic(() => import('../ContentfulFields/ContentfulContainer/ContentfulContainer'));
const ContentfulBanner = dynamic(() => import('../ContentfulFields/ContentfulBanner/ContentfulBanner'));
const ContentfulVideo = dynamic(() => import('../ContentfulFields/ContentfulVideo/ContentfulVideo'));
const ContentfulImageBlock = dynamic(() => import('../ContentfulFields/ContentfulImageBlock/ContentfulImageBlock'));
const ContentfulGroupingTileCarousel = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingTileCarousel/ContentfulGroupingTileCarousel'),
);
const ContentfulGroupingSplit = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingSplit/ContentfulGroupingSplit'),
);
const ContentfulGroupingProducts = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingProducts/ContentfulGroupingProducts'),
);
const ContentfulGroupingProductBanner = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingProductBanner/ContentfulGroupingProductBanner'),
);
const ContentfulGroupingProductCarousel = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingProductCarousel/ContentfulGroupingProductCarousel'),
);
const ContentfulGroupingLeaderboards = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingLeaderboards/ContentfulGroupingLeaderboards'),
);
const ContentfulGroupingHighlight = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingHighlight/ContentfulGroupingHighlight'),
);
const ContentfulGroupingFaqs = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingFaqs/ContentfulGroupingFaqs'),
);
const ContentfulGroupingClickableCards = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingClickableCards/ContentfulGroupingClickableCards'),
);
const ContentfulGroupingCategories = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingCategories/ContentfulGroupingCategories'),
);
const ContentfulFeaturette = dynamic(() => import('../ContentfulFields/ContentfulFeaturette/ContentfulFeaturette'));
const ContentfulFeatureListItem = dynamic(
  () => import('../ContentfulFields/ContentfulFeatureListItem/ContentfulFeatureListItem'),
);
const ContentfulFeatureList = dynamic(() => import('../ContentfulFields/ContentfulFeatureList/ContentfulFeatureList'));
const ContentfulComponentGrouping = dynamic(
  () => import('../ContentfulFields/ContentfulComponentGrouping/ContentfulComponentGrouping'),
);
const ContentfulChecklist = dynamic(() => import('../ContentfulFields/ContentfulChecklist/ContentfulChecklist'));
const ContentfulCallToAction = dynamic(
  () => import('../ContentfulFields/ContentfulCallToAction/ContentfulCallToAction'),
);
const ContentfulBlockRelatedArticles = dynamic(
  () => import('../ContentfulFields/ContentfulBlockRelatedArticles/ContentfulBlockRelatedArticles'),
);
const ContentfulBlockDynamicProducts = dynamic(
  () => import('../ContentfulFields/ContentfulBlockDynamicProducts/ContentfulBlockDynamicProducts'),
);
const ContentfulFieldRenderer = dynamic(() => import('../ContentfulFieldRenderer/ContentfulFieldRenderer'));
const ContentfulGroupingServices = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingServices/ContentfulGroupingServices'),
);
const ContentfulHTMLComponent = dynamic(
  () => import('../ContentfulFields/ContentfulHTMLComponent/ContentfulHTMLComponent'),
);
const ContentfulIcon = dynamic(() => import('../ContentfulFields/ContentfulIcon/ContentfulIcon'));
const ContentfulRichTextSimple = dynamic(
  () => import('../ContentfulFields/ContentfulRichTextSimple/ContentfulRichTextSimple'),
);
const ContentfulTileService = dynamic(() => import('../ContentfulFields/ContentfulTileService/ContentfulTileService'));

const ContentfulBlockNewsletter = dynamic(
  () => import('../ContentfulFields/ContentfulBlockNewsletter/ContentfulBlockNewsletter'),
);
const ContentfulBlockSocials = dynamic(
  () => import('../ContentfulFields/ContentfulBlockSocials/ContentfulBlockSocials'),
);
const ContentfulBlockTestimonial = dynamic(
  () => import('../ContentfulFields/ContentfulBlockTestimonial/ContentfulBlockTestimonial'),
);
const ContentfulGroupingProductsPromo = dynamic(
  () => import('../ContentfulFields/ContentFulGroupingProductsPromo/ContentFulGroupingProductsPromo'),
);
const ContentfulGroupingSliderText = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingSliderText/ContentfulGroupingSliderText'),
);
const ContentfulGroupingAnchorLinks = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingAnchorLinks/ContentfulGroupingAnchorLinks'),
);
const ContentfulSliderPromotedBrands = dynamic(
  () => import('../ContentfulFields/ContentfulSliderPromotedBrands/ContentfulSliderPromotedBrands'),
);
const ContentfulGroupingImageGrid = dynamic(
  () => import('../ContentfulFields/ContentfulGroupingImageGrid/ContentfulGroupingImageGrid'),
);
const ContentfulTileContentSimple = dynamic(
  () => import('../ContentfulFields/ContentfulTileContentSimple/ContentfulTileContentSimple'),
);
const ContentfulHybridModelMapper = dynamic(() => import('../ContentfulHybridModelMapper/ContentfulHybridModelMapper'));
const ContentfulBlockSponsoredProducts = dynamic(
  () => import('../ContentfulFields/ContentfulBlockSponsoredProducts/ContentfulBlockSponsoredProducts'),
);

interface MapperProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  additionalProps?: any;
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content?: any;
}

const Mapper = memo(({ additionalProps, className, content }: MapperProps) => {
  const contentType = content.sys.contentType.sys.id;

  const modelProps = { ...additionalProps, className, content };

  switch (contentType) {
    case CONTENTFUL_TYPES.ADVENT_CALENDAR:
      return <ContentfulAdventCalendar {...modelProps} />;
    case CONTENTFUL_TYPES.BANNER:
      return <ContentfulBanner {...modelProps} />;
    case CONTENTFUL_TYPES.BLOCK_DYNAMIC_PRODUCTS:
      return <ContentfulBlockDynamicProducts {...modelProps} />;
    case CONTENTFUL_TYPES.BLOCK_SPONSORED_PRODUCTS:
      return <ContentfulBlockSponsoredProducts {...modelProps} />;
    case CONTENTFUL_TYPES.BLOCK_IFRAME:
      return <ContentfulHTMLComponent {...modelProps} />;
    case CONTENTFUL_TYPES.BLOCK_IMAGE:
      return <ContentfulImageBlock {...modelProps} />;
    case CONTENTFUL_TYPES.BLOCK_NEWSLETTER:
      return <ContentfulBlockNewsletter {...modelProps} />;
    case CONTENTFUL_TYPES.BLOCK_RELATED_ARTICLES:
      return <ContentfulBlockRelatedArticles {...modelProps} />;
    case CONTENTFUL_TYPES.BLOCK_SOCIALS:
      return <ContentfulBlockSocials {...modelProps} />;
    case CONTENTFUL_TYPES.BLOCK_TESTIMONIAL:
      return <ContentfulBlockTestimonial {...modelProps} />;
    case CONTENTFUL_TYPES.CALL_TO_ACTION:
      return <ContentfulCallToAction {...modelProps} />;
    case CONTENTFUL_TYPES.CHECKLIST:
      return <ContentfulChecklist {...modelProps} />;
    case CONTENTFUL_TYPES.CONTAINER:
      return <ContentfulContainer {...modelProps} />;
    case CONTENTFUL_TYPES.COMPONENT_GROUPING:
      return <ContentfulComponentGrouping {...modelProps} />;
    case CONTENTFUL_TYPES.FEATURE_LIST_ITEM:
      return <ContentfulFeatureListItem {...modelProps} />;
    case CONTENTFUL_TYPES.FEATURE_LIST:
      return <ContentfulFeatureList {...modelProps} />;
    case CONTENTFUL_TYPES.FEATURETTE:
      return <ContentfulFeaturette {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_ANCHOR_LINKS:
      return <ContentfulGroupingAnchorLinks {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_CATEGORIES:
      return <ContentfulGroupingCategories {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_CLICKABLE_CARDS:
      return <ContentfulGroupingClickableCards {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_FAQS:
      return <ContentfulGroupingFaqs {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_IMAGE_GRID:
      return <ContentfulGroupingImageGrid {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_LEADERBOARDS:
      return <ContentfulGroupingLeaderboards {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_PRODUCT_BANNER:
      return <ContentfulGroupingProductBanner {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_PRODUCT_CAROUSEL:
      return <ContentfulGroupingProductCarousel {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_PRODUCTS:
      return <ContentfulGroupingProducts {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_PRODUCTS_PROMO:
      return <ContentfulGroupingProductsPromo {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_SERVICES:
      return <ContentfulGroupingServices {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_SLIDER_TEXT:
      return <ContentfulGroupingSliderText {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_SPLIT:
      return <ContentfulGroupingSplit {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_TILE_CAROUSEL:
      return <ContentfulGroupingTileCarousel {...modelProps} />;
    case CONTENTFUL_TYPES.GROUPING_USP:
      return <ContentfulGroupingHighlight {...modelProps} />;
    case CONTENTFUL_TYPES.ICON:
      return <ContentfulIcon {...modelProps} />;
    case CONTENTFUL_TYPES.TILE_SERVICE:
      return <ContentfulTileService {...modelProps} />;
    case CONTENTFUL_TYPES.SLIDER_PROMOTED_BRANDS:
      return <ContentfulSliderPromotedBrands {...modelProps} />;
    case CONTENTFUL_TYPES.VIDEO:
      return <ContentfulVideo {...modelProps} />;
    case CONTENTFUL_TYPES.TILE_CONTENT:
      return <ContentfulTileContentSimple {...modelProps} />;
    case CONTENTFUL_TYPES.RICH_TEXT:
      return (
        <ContentfulFieldRenderer
          additionalProps={additionalProps}
          className={className}
          richTextContent={content.fields.content}
        />
      );
    case CONTENTFUL_TYPES.CONTENT_BODY:
      return (
        <ContentfulFieldRenderer
          additionalProps={additionalProps}
          centerContent
          className={className}
          richTextContent={content.fields.content}
        />
      );
    case CONTENTFUL_TYPES.RICH_TEXT_SIMPLE:
      return <ContentfulRichTextSimple {...modelProps} />;
    case CONTENTFUL_TYPES.CONTENT_DEFAULT:
    case CONTENTFUL_TYPES.CONTENT_PROMO:
    case CONTENTFUL_TYPES.CONTENT_THEME:
      return <ContentfulTemplate additionalProps={additionalProps} content={content} />;
    case CONTENTFUL_TYPES.CONTENT_HYBRID:
      return <ContentfulHybridModelMapper content={content} {...modelProps} />;
    default:
      return <p>{`EMBEDDED_ENTRY - Type: ${contentType}`}</p>;
  }
}, isEqual);

Mapper.displayName = 'Mapper';

const ContentfulModelMapper = ({ additionalProps, content, ...props }: MapperProps) => {
  if (!content?.fields) {
    return null;
  }

  return (
    <ErrorBoundary message="ERROR: This will be hidden when live. Please report!">
      <Mapper content={content} additionalProps={additionalProps} {...props} />
    </ErrorBoundary>
  );
};

export default ContentfulModelMapper;
